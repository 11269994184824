import React from 'react'
import LayoutBlog from '../../components/layout-blog'
import SEO from '../../components/seo'

const BestBeginnerDrone = () => {
  
  return (
    <LayoutBlog>
      <SEO />

      <a href="https://www.dji.com/downloads/products/mavic-mini">Mavic Mini</a>




    </LayoutBlog>
  )
}

export default BestBeginnerDrone