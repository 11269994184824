import React from 'react'
import Header from '../components/header'
import Footer from '../components/footer'

const LayoutBlog = ({children}) => {

  return (

    <>
      <Header />

      <main className="section-main">
      {children}
      </main>

      <Footer />
    </>
    
  )
}

export default LayoutBlog